export const generateImage = (fileToChange) => {
  const reader = new FileReader();
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    reader.onload = function (event) {
      const img = new Image();
      img.onload = function () {
        const w = img.width;
        const h = img.height;
        canvas.width = w;
        canvas.height = h;
        ctx.drawImage(img, 0, 0, w, h);
        return resolve(canvas.toDataURL(fileToChange.type));
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(fileToChange);
  });
};
