// receives the time in format: date.getTime()
// converts it to am or pm time.
const tConvertOne = (timeOne) => {
  // first convert the time to hours:mins
  const timeData = `${new Date(timeOne).getHours()}:${
    new Date(timeOne).getMinutes() === 0 ? '00' : new Date(timeOne).getMinutes()
  }`;
  let timeM = timeData
    .toString()
    .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [timeData];
  if (timeM.length > 1) {
    timeM = timeM.slice(1);
    timeM[5] = +timeM[0] < 12 ? ' AM' : ' PM';
    timeM[0] = +timeM[0] % 12 || 12;
  }
  return timeM.join('');
};

export { tConvertOne };
