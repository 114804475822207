import React from 'react';
import PropTypes from 'prop-types';
import Button from '@doctoroncallcom/dermis-web/dist/esm/Button/Button';
import './style.css';

const FriendlyReminderBox = ({ setShowFriendlyReminder }) => (
  <div className="bg-theme-secon-bg border rounded-lg border-theme-line fr-box">
    <div className="bg-theme-main-blue w-full h-2 rounded-t-lg" />
    <div className="flex flex-row items-center pl-6 py-5">
      <img src="/assets/icons/instruction-icon.svg" alt="icon" />
      <div className="text-lg font-semibold text-theme-font pl-4">
        Friendly Reminder for Patients
      </div>
    </div>
    <div className="h-px border-b border-theme-line mx-5" />
    <div className="flex flex-col pt-5 pb-7 pl-10 pr-5 gap-5">
      <ul className="list-decimal text-theme-font text-base font-normal">
        <li>Please dress modestly and appropriately.</li>
        <li>
          Please ensure you have a{' '}
          <span className="text-base font-bold">
            stable internet connection.
          </span>
        </li>
        <li>
          Be in a{' '}
          <span className="text-base font-bold">quiet environment.</span>
        </li>
        <li>
          Preferably <span className="text-base font-bold">wear earphones</span>{' '}
          to communicate better.
        </li>
      </ul>
      <div className="flex flex-row justify-end">
        <div className="w-1/2">
          <Button
            primary={true}
            label="I Understand"
            height="48px"
            type="button"
            width="100%"
            greenBtn={true}
            onClick={() => setShowFriendlyReminder(false)}
            centerContent={true}
          />
        </div>
      </div>
    </div>
  </div>
);

FriendlyReminderBox.propTypes = {
  setShowFriendlyReminder: PropTypes.func.isRequired,
};

FriendlyReminderBox.defaultProps = {};

export default FriendlyReminderBox;
