import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import RouteParameter from '../../components/RouteParameter';
// import Layout from '../../features/Layout';
// import Header from '../../features/Header';
// import Footer from '../../features/Footer';

import Consultation from '../../views/Consultation';

const params = [];

const ConsultationRoute = () => {
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      {/* <Layout header={<Header />} footer={<Footer />}> */}
      <Consultation />
      {/* </Layout> */}
    </RouteParameter>
  );
};

ConsultationRoute.propTypes = {};

ConsultationRoute.defaultProps = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(ConsultationRoute);
