import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const VideoActionsOption = ({ img, title, onClickFunc, isError }) => (
  <div className="cursor-pointer text-center option" onClick={onClickFunc}>
    <div
      className={`rounded-lg flex justify-center items-center ${
        isError ? 'top-red' : 'top'
      }`}
    >
      <img src={img} alt="camera icon" />
    </div>
    <span className="text-sm">{title}</span>
  </div>
);

VideoActionsOption.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClickFunc: PropTypes.func.isRequired,
  isError: PropTypes.bool,
};

VideoActionsOption.defaultProps = {
  isError: false,
};

export default VideoActionsOption;
