import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { connect } from 'react-redux';
import * as redux from '@doctoroncallcom/redux';
import Timer from 'react-timer-wrapper';
import Timecode from 'react-timecode';
import moment from 'moment';
import * as reduxInternal from '../../redux/index';
import './style.css';
import VideoActionsOption from '../../components/VideoActionsOption';
import VideoInfoContainer from '../../features/VideoInfoContainer';
// eslint-disable-next-line no-unused-vars
import VideoPreCallTest from '../VideoPreCallTest';
import {
  getConsultationDataFromLS,
  // eslint-disable-next-line no-unused-vars
  setConsultationTimerDataToLS,
} from '../../utils/consultationTimer';
import setMessageToToast from '../../functions/ToastMessages';
import NotificationToastMessages from '../../components/NotificationToastMessages';
import CallStarting from '../../features/WaitingRoomLoaders/CallStarting';
// import CallEnd from '../../features/WaitingRoomLoaders/CallEnd';
import URL from '../../utils/url';
import FriendlyReminderBox from '../../features/FriendlyReminderBox';

// const rtc = {
//   // For the local audio and video tracks.
//   localAudioTrack: null,
//   localVideoTrack: null,
//   client: null,
// };

const PatientVideoConsultationCall = ({
  dispatch,
  // eslint-disable-next-line no-unused-vars
  // profile,
  toggleChat,
  // eslint-disable-next-line no-unused-vars
  setRtcClient,
  rtcClientRef,
  setRtcLocalAudioTrack,
  rtcLocalAudioTrackRef,
  setRtcLocalVideoTrack,
  rtcLocalVideoTrackRef,
  // consultationDetails,
  timeDiffForTimer,
  setTimeDiffForTimer,
  channelJoined,
  consultationIdInStorage,
  // doctorEndedCall,
  // setDoctorLeft,
  rtmChatPeopleDetails,
  setChannelJoined,
  setCallStatus,
  // doctorLeft,
  // doctorInfo,
}) => {
  const params = new URL().getParams();
  const navigate = useNavigate();
  const [callStartLoader, setCallStartLoader] = useState(true);
  const [localVideoTrack, setLocalVideoTrack] = useState(false);
  const [localAudioTrack, setLocalAudioTrack] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLocalVideoError, setIsLocalVideoError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLocalAudioError, setIsLocalAudioError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [preCallTestCallSuccess, setPreCallTestCallSuccess] = useState(true); // change it to false to allow pre call test
  const [remoteVideoTrack, setRemoteVideoTrack] = useState(false);
  const [remoteAudioTrack, setRemoteAudioTrack] = useState(false);
  const [showFriendlyReminder, setShowFriendlyReminder] = useState(true);
  // const [remoteContainersBackupMsgs, setRemoteContainersBackupMsgs] = useState([
  //   {
  //     name: consultationDetails?.doc?.name || '',
  //     message: 'Please wait, the doctor will be there shortly.',
  //   },
  // ]);
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();

  // remote users
  // const remoteUsers = useRemoteUsers();
  // const { audioTracks } = AgoraRTC.useRemoteAudioTracks(remoteUsers);

  const CreateLocalTracks = useCallback(async () => {
    // Create a local audio track from the audio sampled by a microphone.
    try {
      // rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      await setRtcLocalAudioTrack(await AgoraRTC.createMicrophoneAudioTrack());
      // dispatch(
      //   reduxInternal.videoConsultation.actions.setRTCLocalAudioTrack(
      //     rtc.localAudioTrack
      //   )
      // );
      setLocalAudioTrack(true);
      setIsLocalAudioError(false);
    } catch {
      setLocalAudioTrack(false);
      setIsLocalAudioError(true);
    }
    try {
      // Create a local video track from the video captured by a camera.
      // rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
      await setRtcLocalVideoTrack(await AgoraRTC.createCameraVideoTrack());
      // dispatch(
      //   reduxInternal.videoConsultation.actions.setRTCLocalVideoTrack(
      //     rtc.localVideoTrack
      //   )
      // );
      setLocalVideoTrack(true);
      setIsLocalVideoError(false);
    } catch (err) {
      // console.log(err);
      setLocalVideoTrack(false);
      setIsLocalVideoError(true);
    }
    setCallStartLoader(false);
    // Publish the local audio and video tracks to the RTC channel.
    try {
      // await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
      await rtcClientRef.current.publish([
        rtcLocalAudioTrackRef.current,
        rtcLocalVideoTrackRef.current,
      ]);
    } catch {
      // console.log(
      //   'Unable to publish local client. Please try to refresh the page.'
      // );
    }
  });

  const CreateLocalTracksWithoutPublish = useCallback(async () => {
    // Create a local audio track from the audio sampled by a microphone.
    try {
      // rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      await setRtcLocalAudioTrack(await AgoraRTC.createMicrophoneAudioTrack());
      setLocalAudioTrack(true);
      setIsLocalAudioError(false);
    } catch {
      setLocalAudioTrack(false);
      setIsLocalAudioError(true);
    }
    try {
      // Create a local video track from the video captured by a camera.
      // rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
      await setRtcLocalVideoTrack(await AgoraRTC.createCameraVideoTrack());
      setLocalVideoTrack(true);
      setIsLocalVideoError(false);
    } catch (err) {
      // console.log(err);
      setLocalVideoTrack(false);
      setIsLocalVideoError(true);
    }
    setCallStartLoader(false);
  });

  const stopLocalTracks = async () => {
    // eslint-disable-next-line no-underscore-dangle
    if (rtcLocalVideoTrackRef.current?._enabled) {
      // eslint-disable-next-line no-underscore-dangle
      await rtcLocalVideoTrackRef.current.setEnabled(false);
      setLocalVideoTrack(false);
    }
    // eslint-disable-next-line no-underscore-dangle
    if (rtcLocalAudioTrackRef.current?._enabled) {
      // eslint-disable-next-line no-underscore-dangle
      await rtcLocalAudioTrackRef.current.setEnabled(false);
      setLocalAudioTrack(false);
    }
    // Destroy the local audio and video tracks.
    try {
      rtcLocalAudioTrackRef.current.close();
      rtcLocalVideoTrackRef.current.close();
    } catch (e) {
      console.log('Some error occured while closing local tracks', e);
    }
  };

  const stopRemoteTracks = (user) => {
    try {
      user.videoTrack.stop();
      setRemoteVideoTrack(false);
      user.audioTrack.stop();
      setRemoteAudioTrack(false);
    } catch {
      // console.log('Could not remove the remote players');
    }
  };

  // eslint-disable-next-line no-unused-vars
  const leave = useCallback(async () => {
    try {
      stopLocalTracks();
      // Traverse all remote users.
      rtcClientRef.current.remoteUsers.forEach((user) => {
        stopRemoteTracks(user);
      });
      // Leave the channel.
      await rtcClientRef.current.leave();
      // first remove all the data from localstorage and then redirect
      // dispatch(reduxInternal.videoConsultation.actions.resetRTCData());
      dispatch(reduxInternal.videoConsultation.actions.setConsultationId(''));
      dispatch(reduxInternal.rtm.actions.resetAllRTMData());
      setMessageToToast(
        'Redirecting you to the dashboard in a second..',
        'error'
      );
      setTimeout(() => navigate('/'), 2000);
    } catch {
      setMessageToToast(
        'Something went wrong while leaving the call. Please try again or refresh the page.',
        'error'
      );
    }
  });

  const toggleCam = useCallback(async () => {
    // eslint-disable-next-line no-underscore-dangle
    if (rtcLocalVideoTrackRef.current?._enabled) {
      try {
        await rtcLocalVideoTrackRef.current.setEnabled(false);
        setLocalVideoTrack(false);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Some Error Occured', e);
      }
    } else {
      try {
        await rtcLocalVideoTrackRef.current.setEnabled(true);
        setLocalVideoTrack(true);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Some Error Occured', e);
      }
    }
  });

  const toggleMic = useCallback(async () => {
    // eslint-disable-next-line no-underscore-dangle
    if (rtcLocalAudioTrackRef.current?._enabled) {
      try {
        await rtcLocalAudioTrackRef.current.setEnabled(false);
        setLocalAudioTrack(false);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Some Error Occured', e);
      }
    } else {
      try {
        await rtcLocalAudioTrackRef.current.setEnabled(true);
        setLocalAudioTrack(true);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Some Error Occured', e);
      }
    }
  });

  const backToWaitingRoom = async () => {
    try {
      stopLocalTracks();
      // Traverse all remote users.
      rtcClientRef.current.remoteUsers.forEach((user) => {
        stopRemoteTracks(user);
      });
      // Leave the channel.
      await rtcClientRef.current.leave();
      setChannelJoined(false);
      navigate(`../waiting-room?id=${consultationIdInStorage}`);
    } catch {
      setMessageToToast(
        'Could not stop the consultation audio and video properly. Please try again later.',
        'error'
      );
    }
  };

  useEffect(() => {
    if (rtcClientRef.current) {
      rtcClientRef.current.on('user-published', async (user, mediaType) => {
        console.log('====================================');
        console.log(user);
        console.log('====================================');
        console.log('====================================');
        console.log(mediaType);
        console.log('====================================');
        setCallStatus({
          doctorInVideoCall: true,
          doctorInAudioCall: false,
        });
        // setDoctorLeft(false);
        await rtcClientRef.current.subscribe(user, mediaType);
        if (mediaType === 'video') {
          // Get the RemoteVideoTrack object in the AgoraRTCRemoteUser object.
          try {
            user.videoTrack.play(remoteVideoRef.current);
            setRemoteVideoTrack(true);
            console.log('====================================');
            console.log('user: ', user);
            console.log('====================================');
          } catch (e) {
            setRemoteVideoTrack(false);
          }
        }
        if (mediaType === 'audio') {
          try {
            user.audioTrack.play();
            setRemoteAudioTrack(true);
          } catch (e) {
            setRemoteAudioTrack(false);
          }
        }
      });
      rtcClientRef.current.on('user-unpublished', async (user, mediaType) => {
        // await rtcClient.subscribe(user, mediaType);
        if (mediaType === 'video') {
          // Get the RemoteVideoTrack object in the AgoraRTCRemoteUser object.
          try {
            user.videoTrack.stop();
          } catch {
            console.log('It looks like the user video is already unpublished');
          }
          setRemoteVideoTrack(false);
        }
        if (mediaType === 'audio') {
          try {
            user.audioTrack.stop();
          } catch {
            console.log('It looks like the user audio is already unpublished');
          }
          setRemoteAudioTrack(false);
        }
      });
      rtcClientRef.current.on('user-left', async (user, reason) => {
        console.log('====================================');
        console.log(user, reason);
        console.log('====================================');
      });
    }
  }, [rtcClientRef.current]);

  // useEffect(() => {
  //   if (doctorLeft) {
  //     const remoteConsMsgs = [...remoteContainersBackupMsgs];
  //     remoteConsMsgs[0].message =
  //       'Please wait, the doctor will be there shortly.';
  //     setRemoteContainersBackupMsgs(remoteConsMsgs);
  //   }
  //   if (!doctorLeft) {
  //     const remoteConsMsgs = [...remoteContainersBackupMsgs];
  //     remoteConsMsgs[0].message = `${consultationDetails?.doc?.name}'s camera is turned off.`;
  //     setRemoteContainersBackupMsgs(remoteConsMsgs);
  //   }
  // }, [doctorLeft]);

  useEffect(async () => {
    const inSessionTime =
      Number(getConsultationDataFromLS()?.inSessionTime) ||
      new Date().getTime();
    const newDateTime = new Date().getTime();
    setTimeDiffForTimer(newDateTime - inSessionTime);
    if (true) {
      console.log('====================================');
      console.log(channelJoined);
      console.log('====================================');
      // if (channelJoined) {
      if (preCallTestCallSuccess) {
        await stopLocalTracks();
        await setRtcLocalAudioTrack(null);
        await setRtcLocalVideoTrack(null);
        await setCallStartLoader(true);
        await CreateLocalTracks();
      } else {
        await CreateLocalTracksWithoutPublish();
      }
      // Play the local video track.
      // Pass the DIV container and the SDK dynamically creates a player in the container for playing the local video track.
      if (rtcLocalVideoTrackRef.current) {
        rtcLocalVideoTrackRef.current.play(localVideoRef.current);
      } else {
        console.log(`Could not play the local video track.`);
      }
      if (rtcClientRef.current.remoteUsers && preCallTestCallSuccess) {
        if (
          // eslint-disable-next-line no-underscore-dangle
          rtcClientRef.current.remoteUsers[0]?._video_enabled_ &&
          // eslint-disable-next-line no-underscore-dangle
          !rtcClientRef.current.remoteUsers[0]?._video_muted_
        ) {
          try {
            rtcClientRef.current.remoteUsers[0].videoTrack.play(
              remoteVideoRef.current
            );
            setRemoteVideoTrack(true);
          } catch {
            setRemoteVideoTrack(false);
          }
        }
        if (
          // eslint-disable-next-line no-underscore-dangle
          rtcClientRef.current.remoteUsers[0]?._audio_enabled_ &&
          // eslint-disable-next-line no-underscore-dangle
          !rtcClientRef.current.remoteUsers[0]?._audio_muted_
        ) {
          try {
            rtcClientRef.current.remoteUsers[0].audioTrack.play();
            setRemoteAudioTrack(true);
          } catch {
            setRemoteAudioTrack(false);
          }
        }
      }
    } else if (
      consultationIdInStorage &&
      consultationIdInStorage === String(params?.id)
    ) {
      navigate(`../waiting-room?id=${consultationIdInStorage}`);
    } else {
      navigate('/');
    }
  }, [preCallTestCallSuccess]);

  // eslint-disable-next-line no-unused-vars
  const handleJoinCall = () => {
    setPreCallTestCallSuccess(true);
  };

  return (
    <>
      <div className="flex flex-col justify-between call">
        <NotificationToastMessages />
        {callStartLoader && (
          <CallStarting text="Your video call will start in a second..." />
        )}
        {preCallTestCallSuccess ? (
          <>
            <div className="justify-between items-center px-8 font-semibold header-container">
              <div
                className="flex left cursor-pointer"
                onClick={backToWaitingRoom}
              >
                <img
                  src="/assets/icons/video-icons/back-arrow-white.svg"
                  alt="go back icon"
                />
                <div className="inner flex">
                  {moment().format('h:mm a')} &nbsp;&nbsp;|&nbsp;&nbsp; Doctor
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <Timer active={true} duration={null} time={timeDiffForTimer}>
                    <Timecode />
                  </Timer>
                </div>
              </div>
              <div className="flex need-help">
                <img
                  src="/assets/icons/video-icons/need-help-white.svg"
                  alt="need help icon"
                />
                <p>
                  <a
                    href="https://help.doctoroncall.com.my/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Need Help?
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between player-container">
              <div className="flex justify-center videos-con">
                <div className="inner">
                  <div className="flex flex-col justify-center items-center relative local-video-container">
                    {!localVideoTrack && (
                      <VideoInfoContainer
                        avatar=""
                        name={rtmChatPeopleDetails.userName}
                        // message={`${peopleDetails?.doctorName}’s webcam isn’t enabled yet`}
                        message=""
                        local={true}
                      />
                    )}
                    <div ref={localVideoRef} className="videoRefCon" />
                  </div>
                  <div className="flex flex-col justify-center items-center remote-video-container">
                    <div className="remote-user-mic">
                      <img
                        src={`/assets/icons/video-icons/microphone${
                          !remoteAudioTrack ? '-red' : ''
                        }.svg`}
                        alt="remote user microphone"
                      />
                    </div>
                    {!remoteVideoTrack && (
                      <VideoInfoContainer
                        avatar="/assets/avatar-4.png"
                        name="User"
                        message="Waiting User"
                        local={false}
                      />
                    )}
                    {/* <div id="remoteVideoGrid"> */}
                    <div ref={remoteVideoRef} className="videoRefCon" />
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center options-container">
                <div className="flex gap-3 md:gap-6 inner">
                  <VideoActionsOption
                    img={`/assets/icons/video-icons/camera${
                      !localVideoTrack ? '-red' : ''
                    }.svg`}
                    title="Cam"
                    onClickFunc={toggleCam}
                  />
                  <VideoActionsOption
                    img={`/assets/icons/video-icons/microphone${
                      !localAudioTrack ? '-red' : ''
                    }.svg`}
                    title="Mic"
                    onClickFunc={toggleMic}
                  />
                  <VideoActionsOption
                    img="/assets/icons/video-icons/chat.svg"
                    title="Chat"
                    onClickFunc={toggleChat}
                  />
                  <VideoActionsOption
                    img="/assets/icons/video-icons/leave-call.svg"
                    title="Leave"
                    onClickFunc={leave}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <VideoPreCallTest
            handleJoinCall={handleJoinCall}
            localVideoRef={localVideoRef}
            localVideoTrack={localVideoTrack}
            localAudioTrack={localAudioTrack}
            isLocalVideoError={isLocalVideoError}
            isLocalAudioError={isLocalAudioError}
            toggleCam={toggleCam}
            toggleMic={toggleMic}
          />
        )}
      </div>
      {showFriendlyReminder ? (
        <FriendlyReminderBox
          setShowFriendlyReminder={setShowFriendlyReminder}
        />
      ) : null}
    </>
  );
};

PatientVideoConsultationCall.propTypes = {
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // profile: PropTypes.object.isRequired,
  toggleChat: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  setRtcClient: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rtcClientRef: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  setRtcLocalAudioTrack: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rtcLocalAudioTrackRef: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  setRtcLocalVideoTrack: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rtcLocalVideoTrackRef: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // consultationDetails: PropTypes.any,
  timeDiffForTimer: PropTypes.number.isRequired,
  setTimeDiffForTimer: PropTypes.func.isRequired,
  channelJoined: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  consultationIdInStorage: PropTypes.any.isRequired,
  // doctorEndedCall: PropTypes.bool.isRequired,
  // setDoctorLeft: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rtmChatPeopleDetails: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  setChannelJoined: PropTypes.any.isRequired,
  setCallStatus: PropTypes.func,
  // doctorLeft: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  // doctorInfo: PropTypes.object.isRequired,
};

PatientVideoConsultationCall.defaultProps = {
  // rtcClient: null,
  // rtcLocalAudioTrack: null,
  // rtcLocalVideoTrack: null,
  // consultationDetails: null,
  setCallStatus: () => {},
  // doctorLeft: false,
};

const mapStateToProps = (state) => ({
  profile: redux.user.selectors.profile()(state),
  consultationIdInStorage:
    reduxInternal.videoConsultation.selectors.makeSelectConsultationId()(state),
  rtmChatPeopleDetails:
    reduxInternal.rtm.selectors.makeSelectRTMChatPeopleDetails()(state),
});

export default connect(mapStateToProps)(PatientVideoConsultationCall);
