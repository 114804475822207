const setConsultationTimerDataToLS = (
  inSessionTime,
  docConsultationId,
  rtcToken,
  rtmToken
) => {
  localStorage.setItem(
    'consultationDetailsInLS',
    JSON.stringify({
      inSessionTime,
      docConsultationId,
      rtcToken,
      rtmToken,
    })
  );
};

const getConsultationDataFromLS = () =>
  JSON.parse(localStorage.getItem('consultationDetailsInLS'));

const removeConsultationTimerDataFromLS = () => {
  localStorage.removeItem('consultationDetailsInLS');
};

export {
  setConsultationTimerDataToLS,
  getConsultationDataFromLS,
  removeConsultationTimerDataFromLS,
};
