import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { RtcTokenBuilder, RtmTokenBuilder, RtcRole } from 'agora-access-token';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import refState from 'react-usestateref';
import AgoraRTC from 'agora-rtc-sdk-ng';
import * as redux from '@doctoroncallcom/redux';
import { connect } from 'react-redux';
import * as reduxInternal from '../../redux/index';
import AgoraChat from '../../features/Chat';
// import PatientWaitingRoom from '../PatientWaitingRoom/PatientWaitingRoom';
import PatientVideoConsultationCall from '../PatientVideoConsultationCall';
import {
  getConsultationDataFromLS,
  setConsultationTimerDataToLS,
} from '../../utils/consultationTimer';
// import setMessageToToast from '../../functions/ToastMessages';
import NotificationToastMessages from '../../components/NotificationToastMessages';

import './style.css';
import URL from '../../utils/url';

function Consultation({ dispatch }) {
  // eslint-disable-next-line no-unused-vars
  const [rtcClient, setRtcClient, rtcClientRef] = refState(null);
  // eslint-disable-next-line no-unused-vars
  const [rtcLocalAudioTrack, setRtcLocalAudioTrack, rtcLocalAudioTrackRef] =
    refState(null);
  // eslint-disable-next-line no-unused-vars
  const [rtcLocalVideoTrack, setRtcLocalVideoTrack, rtcLocalVideoTrackRef] =
    refState(null);

  const navigate = useNavigate();
  const params = new URL(window.location).getParams();
  const [agoraConfig, setAgoraConfig] = useState(null);

  // eslint-disable-next-line no-unused-vars
  // const [doctorSearchStatus, setDoctorSearchStatus] = useState(true);
  // const [statuses, setStatuses] = useState({
  //   preferenceStatus: false,
  //   preferenceSet: false,
  // });
  // const [callStatus, setCallStatus] = useState({
  //   doctorInVideoCall: false,
  //   doctorInAudioCall: false,
  // });
  // const [medicationDeliveryStatus, setMedicationDeliveryStatus] =
  //   useState(false);
  const [showChat, setShowChat] = useState(false);
  // const [preferenceData, setPreferenceData] = useState({
  //   consultationMode: 'Video',
  // });
  // const [countDownCounter, setCountDownCounter] = useState(10);
  // const [preparingCardMsgs, setPreparingCardMsgs] = useState({
  //   titleMsg: 'Doctor is ready for you',
  //   callType: 'Video',
  //   callStatus: 'Incoming Video Call',
  //   callTypeMsg: '',
  // });
  const [timeDiffForTimer, setTimeDiffForTimer] = useState(0);
  const [checkStatusCounter, setCheckStatusCounter] = useState(20);
  // const [dateTimeNotSet, setDateTimeNotSet] = useState(true);
  const [channelJoined, setChannelJoined] = useState(false);
  // const [dataSetToRedux, setDataSetToRedux] = useState(false);
  // const [doctorEndedCall, setDoctorEndedCall] = useState(false);
  // const [doctorLeft, setDoctorLeft] = useState(false);
  // const [doctorLeftCounter, setDoctorLeftCounter] = useState(5);
  const [showJoinNotification, setShowJoinNotification] = useState(false);
  // const [prevConsultationStatus, setPrevConsultationStatus] = useState(null);
  // const [cTypeAllowed, setCTypeAllowed] = useState({
  //   video: false,
  //   audio: false,
  //   valueSet: false,
  // });
  // const [showCallPreference, setShowCallPreference] = useState(true);
  // const [consultationDetails, setConsultationDetails] = useState(null);
  // const [doctorInfo, setDoctorInfo] = useState({});

  // eslint-disable-next-line no-unused-vars

  const generateAgoraToken = (
    appId,
    appCertificate,
    channelName,
    userId,
    roleType
  ) => {
    const account = userId;
    const role = roleType ? RtcRole.PUBLISHER : RtcRole.SUBSCRIBER;
    // const role = RtcRole.PUBLISHER;
    const expirationTimeInSeconds = 86000;
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const privilegeExpiredTs = currentTimestamp + expirationTimeInSeconds;
    // const rtc = RtcTokenBuilder.buildTokenWithUid(appId, appCertificate, channelName, uid, role, privilegeExpiredTs);
    const rtc = RtcTokenBuilder.buildTokenWithAccount(
      appId,
      appCertificate,
      channelName,
      account,
      role,
      privilegeExpiredTs
    );
    const rtm = RtmTokenBuilder.buildToken(
      appId,
      appCertificate,
      account,
      role,
      privilegeExpiredTs
    );
    return {
      rtc,
      rtm,
    };
  };

  const guestId = uuidv4();
  const patient = generateAgoraToken(
    '88ac81dc726846e9932d2cdeb8c2652f',
    'b6974880a29b48a881842dda1395408c',
    params.id,
    guestId,
    false
  );

  const checkConsultationStatusData = {
    id: params.id,
    agora: {
      appId: params.app,
      channelName: params.id,
      token: {
        patient: {
          rtc: patient.rtc,
          rtm: params.rtm,
        },
      },
    },
    uuid: guestId,
  };

  console.log('====================================');
  console.log(checkConsultationStatusData);
  console.log('====================================');

  const joinClient = useCallback(async () => {
    await rtcClientRef.current.setClientRole('host');
    // Join an RTC channel.
    try {
      await rtcClientRef.current.join(
        checkConsultationStatusData?.agora?.appId,
        checkConsultationStatusData?.agora?.channelName,
        checkConsultationStatusData?.agora?.token?.patient?.rtc,
        checkConsultationStatusData.uuid
      );
    } catch (e) {
      console.log('some random error occured');
    }
    setChannelJoined(true);
  });

  const joinClientAndRedirect = useCallback(async () => {
    await rtcClientRef.current.setClientRole('host');
    // Join an RTC channel.
    try {
      await rtcClientRef.current.join(
        checkConsultationStatusData?.agora?.appId,
        checkConsultationStatusData?.agora?.channelName,
        checkConsultationStatusData?.agora?.token?.patient?.rtc,
        guestId
      );
      setChannelJoined(true);
      navigate(
        `../../patient/consultation/consultation-room?id=${checkConsultationStatusData?.id}`,
        { replace: true }
      );
    } catch (e) {
      console.log('some random error occured');
    }
  });

  const setAgoraChatAndVideoConfig = async () => {
    // agora credentials are now available
    // initiate the chat as well.
    setAgoraConfig(checkConsultationStatusData?.agora);
    // Create an AgoraRTCClient object.
    const clientL = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
    await setRtcClient(clientL);
    dispatch(
      reduxInternal.rtm.actions.setRTMChatConfig({
        appId: checkConsultationStatusData?.agora?.appId,
        channelName: checkConsultationStatusData?.agora?.channelName,
        rtmToken: checkConsultationStatusData?.agora?.token?.patient?.rtm,
        accountName: guestId,
      })
    );
    joinClient();
    setTimeout(() => {
      // setDataSetToRedux(true);
    }, [200]);
  };

  const joinCallFunc = () => {
    if (channelJoined) {
      navigate(
        `../../patient/consultation/consultation-room?id=${checkConsultationStatusData?.id}`,
        { replace: true }
      );
    } else {
      joinClientAndRedirect();
    }
  };

  useEffect(() => {
    if (rtcClientRef.current) {
      rtcClientRef.current.on('user-published', async (user, mediaType) => {
        await rtcClientRef.current.subscribe(user, mediaType);
        if (
          !getConsultationDataFromLS()?.inSessionTime ||
          checkConsultationStatusData?.agora?.token?.patient?.rtc !==
            getConsultationDataFromLS()?.rtcToken
        ) {
          const dateNow = new Date().getTime();
          setConsultationTimerDataToLS(
            dateNow,
            checkConsultationStatusData?.id,
            checkConsultationStatusData?.agora?.token?.patient?.rtc,
            checkConsultationStatusData?.agora?.token?.patient?.rtm
          );
        }
      });

      rtcClientRef.current.on('user-left', async () => {});
    }
  }, [rtcClientRef.current]);

  const toggleChat = () => {
    setShowChat(!showChat);
  };

  useEffect(() => {
    const timer =
      checkStatusCounter < 8 &&
      setInterval(() => setCheckStatusCounter(checkStatusCounter + 1), 1000);
    if (checkStatusCounter === 8) {
      dispatch(
        redux.consultations.actions.checkPatientConsultationStatus(params.id)
      );
      setCheckStatusCounter(0);
    }
    return () => clearInterval(timer);
  }, [checkStatusCounter]);

  // useEffect(() => {
  //   if (!params.id) {
  //     navigate(new URL(window.location.origin).replaceParams({}).getUrl());
  //   }
  //   dispatch(
  //     redux.consultations.actions.checkPatientConsultationStatus(params.id)
  //   );
  //   if (consultationIdInStorage !== params.id) {
  //     // dispatch(reduxInternal.videoConsultation.actions.resetRTCData());
  //     dispatch(reduxInternal.rtm.actions.resetAllRTMData());
  //   }
  //   dispatch(
  //     reduxInternal.videoConsultation.actions.setConsultationId(params.id)
  //   );

  //   // get complete consultation data
  //   dispatch(
  //     redux.consultations.actions.getConsultationHistoryDetails(params.id)
  //   );

  //   // change the counter value to 0 to initialize the request after 15 seconds
  //   // because the first request is automatically being sent on page load.
  //   setTimeout(() => {
  //     setCheckStatusCounter(0);
  //   }, [5000]);
  // }, []);
  useEffect(() => {
    setAgoraChatAndVideoConfig();
  }, []);
  // console.log(checkConsultationStatusData);
  // console.log(prevConsultationStatus);
  return (
    <div className="cp-content">
      <div className="cp-content-inner">
        <NotificationToastMessages />
        <div
          className={classNames('flex flex-row cp-ci-main', {
            'cp-ci-main-small': showChat,
            'cp-ci-main-full': !showChat,
            'px-3 pb-4 md:px-6 md:pb-6':
              window.location.pathname.includes('waiting-room'),
          })}
        >
          <Routes>
            {/* <Route
              path="waiting-room"
              element={
                <PatientWaitingRoom
                  toggleChat={toggleChat}
                  bannerData={bannerData}
                  doctorSearchStatus={doctorSearchStatus}
                  statuses={statuses}
                  medicationDeliveryStatus={medicationDeliveryStatus}
                  // createConsultationResponse={createConsultationResponse}
                  consultationDetailsData={checkConsultationStatusData}
                  consultationDetails={consultationDetails}
                  // consultationDetailsDataError={checkConsultationStatusError}
                  preferenceData={preferenceData}
                  countDownCounter={countDownCounter}
                  changeConsultationMode={changeConsultationMode}
                  doctorInfo={doctorInfo}
                  preparingCardMsgs={preparingCardMsgs}
                  timeDiffForTimer={timeDiffForTimer}
                  callStatus={callStatus}
                  setMedicationDeliveryStatus={setMedicationDeliveryStatus}
                  showCallPreference={showCallPreference}
                  joinCallFunc={joinCallFunc}
                  resetAllConsultationData={resetAllConsultationData}
                  setBannerData={setBannerData}
                  setPreparingCardMsgs={setPreparingCardMsgs}
                />
              }
            /> */}
            <Route
              path="consultation-room"
              element={
                <PatientVideoConsultationCall
                  toggleChat={toggleChat}
                  timeDiffForTimer={timeDiffForTimer}
                  setTimeDiffForTimer={setTimeDiffForTimer}
                  channelJoined={channelJoined}
                  setChannelJoined={setChannelJoined}
                  // doctorLeft={doctorLeft}
                  // setDoctorLeft={setDoctorLeft}
                  // doctorEndedCall={doctorEndedCall}
                  setRtcClient={setRtcClient}
                  rtcClientRef={rtcClientRef}
                  setRtcLocalAudioTrack={setRtcLocalAudioTrack}
                  rtcLocalAudioTrackRef={rtcLocalAudioTrackRef}
                  setRtcLocalVideoTrack={setRtcLocalVideoTrack}
                  rtcLocalVideoTrackRef={rtcLocalVideoTrackRef}
                  // setCallStatus={setCallStatus}
                  // consultationDetails={consultationDetails}
                  // doctorInfo={doctorInfo}
                />
              }
            />
          </Routes>
        </div>
        <AgoraChat
          showChat={showChat}
          toggleChat={toggleChat}
          config={agoraConfig}
          showJoinNotification={showJoinNotification}
          setShowJoinNotification={setShowJoinNotification}
          joinCallFunc={joinCallFunc}
        />
      </div>
    </div>
  );
}

Consultation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  // profile: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // createConsultationResponse: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  // checkConsultationStatusData: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  // checkConsultationStatusError: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  // planIdResponse: PropTypes.any,
  // planIdResponseLoading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  // planIdResponseError: PropTypes.any,
  // consultationIdInStorage: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  // getConsultationHistoryDetailsSuccess: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  // getFileResponse: PropTypes.any,
};

Consultation.defaultProps = {
  // createConsultationResponse: null,
  // checkConsultationStatusData: null,
  // checkConsultationStatusError: null,
  // planIdResponse: null,
  // planIdResponseLoading: false,
  // planIdResponseError: null,
  // consultationIdInStorage: '',
  // getConsultationHistoryDetailsSuccess: null,
  // getFileResponse: null,
};

const mapStateToProps = () => ({
  // profile: redux.user.selectors.profile()(state),
  // createConsultationResponse:
  //   redux.consultations.selectors.createConsultation()(state),
  // checkConsultationStatusData:
  //   redux.consultations.selectors.selectPatientConsultationStatusData()(state),
  // checkConsultationStatusError:
  //   redux.consultations.selectors.selectPatientConsultationStatusDataError()(
  //     state
  //   ),
  // planIdResponse: redux.plan.selectors.getPlanForPrice()(state),
  // planIdResponseLoading: redux.plan.selectors.getPlanForPriceLoading()(state),
  // planIdResponseError: redux.plan.selectors.getPlanForPriceError()(state),
  // consultationIdInStorage:
  //   reduxInternal.videoConsultation.selectors.makeSelectConsultationId()(state),
  // getConsultationHistoryDetailsSuccess:
  //   redux.consultations.selectors.getConsultationHistoryDetails()(state),
  // getFileResponse: redux.fileDomain.selectors.getFile()(state),
});

export default connect(mapStateToProps)(Consultation);
