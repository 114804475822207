import { toast } from 'react-toastify';

const setMessageToToast = (message, type) => {
  toast[type](message, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export default setMessageToToast;
