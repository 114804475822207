import React from 'react';
import PropTypes from 'prop-types';
import Button from '@doctoroncallcom/dermis-web/dist/esm/Button/Button';
import './style.css';
import { useNavigate, useParams } from 'react-router-dom';

// eslint-disable-next-line no-unused-vars
const JoinCallCard = ({
  handleJoinCall,
  isLocalVideoError,
  isLocalAudioError,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  window.onbeforeunload = () => {
    window.confirm = () => {
      navigate(`../../patient/consultation/waiting-room?id=${params.id}`);
      <JoinCallCard
        handleJoinCall={handleJoinCall}
        isLocalAudioError={true}
        isLocalVideoError={true}
      />;
      return true;
    };
  };
  return (
    <div className="flex items-center justify-center rounded-2xl text-white join-call-card px-7 py-6 w-5/6 lg:w-3/5 xl:w-1/2">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          {(isLocalVideoError || isLocalAudioError) && (
            <h4 className="font-semibold text-base xl:text-xl text-center">
              There seems to be an issue with your{' '}
              {isLocalAudioError ? 'microphone' : ''}
              {isLocalVideoError && isLocalAudioError ? ' and ' : ''}
              {isLocalVideoError ? 'camera' : ''}, please check your settings.
            </h4>
          )}
          {(isLocalVideoError || isLocalAudioError) && (
            <h4 className="font-semibold text-base xl:text-xl text-center">
              There may be an issue with your permissions.
            </h4>
          )}
          {!isLocalVideoError && !isLocalAudioError && (
            <h4 className="font-semibold text-lg xl:text-xl text-center">
              You are all set, please join the call.
            </h4>
          )}
        </div>
        <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
          <div>
            <Button
              type="button"
              label="Cancel"
              primary={false}
              blueText={true}
              width="150px"
              height="32px"
              centerContent={true}
              onClick={() =>
                navigate(
                  `../../patient/consultation/waiting-room?id=${params.id}`
                )
              }
            />
          </div>
          <div>
            <Button
              type="button"
              label="Join Now"
              primary={true}
              width="150px"
              height="32px"
              centerContent={true}
              onClick={handleJoinCall}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

JoinCallCard.propTypes = {
  handleJoinCall: PropTypes.func.isRequired,
  isLocalVideoError: PropTypes.bool,
  isLocalAudioError: PropTypes.bool,
};

JoinCallCard.defaultProps = {
  isLocalVideoError: false,
  isLocalAudioError: false,
};

export default JoinCallCard;
