import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const ChatImageAttachment = ({ fileName, size, src, openLightBox, msgBy }) => (
  <div>
    <p>{fileName}</p>
    <p>{size}</p>
    <p
      onClick={() => openLightBox(src)}
      className="flex cursor-pointer hover:underline gap-2.5 text-sm"
    >
      View Attachment{' '}
      {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
      <img
        style={{ width: '20px' }}
        src={`/assets/icons/action-icons/view${
          msgBy === 'Doc' ? '-white' : ''
        }.svg`}
        alt="view image"
      />
    </p>
  </div>
);

ChatImageAttachment.propTypes = {
  fileName: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  src: PropTypes.any.isRequired,
  openLightBox: PropTypes.func.isRequired,
  msgBy: PropTypes.string.isRequired,
};

ChatImageAttachment.defaultProps = {};

export default ChatImageAttachment;
