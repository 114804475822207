import React, { useEffect, useState, useRef } from 'react';
import refState from 'react-usestateref';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import * as redux from '@doctoroncallcom/redux';
import Button from '@doctoroncallcom/dermis-web/dist/esm/Button/Button';
import * as reduxInternal from '../../redux/index';
import RtmClient from './rtmClient';
import { blobToImage } from './common';
import './style.css';
import LightBoxComp from '../../components/LightBox';
import ChatImageAttachment from '../../components/ChatMessages/ChatImageAttachment';
import { generateImage } from '../../functions/GenerateImageFromFile';
import { tConvertOne } from '../../functions/TimeConversion';
import ToastMessages from '../../functions/ToastMessages';
import NotificationToastMessages from '../../components/NotificationToastMessages';

const rtm = new RtmClient();

const AgoraChat = ({
  dispatch,
  showChat,
  toggleChat,
  profile,
  consultationDetailsData,
  rtmChatConfigRedux,
  rtmMsgByDoc,
  rtmMsgsArr,
  rtmChatPeopleDetails,
  fileUploadResponse,
  showJoinNotification,
  setShowJoinNotification,
  getUploadFileUrlResponse,
  getUploadFileUrlError,
  joinCallFunc,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [chatConfig, setChatConfig] = useState({
    appId: rtmChatConfigRedux.appId,
    accountName: rtmChatConfigRedux.accountName,
    channelName: rtmChatConfigRedux.channelName,
    token: rtmChatConfigRedux.rtmToken,
  });
  const [msgInput, setMsgInput] = useState('');
  const [lightBoxImage, setLightBoxImage] = useState([]);
  const [isOpenLightBox, setIsOpenLightBox] = useState(false);
  const fileInputRef = useRef(null);
  const chatConRef = useRef(null);
  const [chatScrolling, setChatScrolling] = useState({
    // chatScrollHeight only to be changes on initialization.
    // so that we can detect the change event in ChatScrolling
    // and then initially scroll to the bottom of the chat.
    chatScrollHeight: 0,
    firstScroll: false,
    scrollTop: 0,
  });
  const [fileUploadObj, setFileUploadObj] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [rtmMsgByDocMod, setRtmMsgByDocMod, rtmMsgByDocModRef] =
    refState(rtmMsgByDoc);

  useEffect(() => {
    setRtmMsgByDocMod(rtmMsgByDoc);
  }, [rtmMsgByDoc]);

  const scrollDownOnMessage = (scrollTopBeforeMsg) => {
    // scroll it down only if the user is within 200 pixels of full scrolling.
    const scrollHeightDiff =
      chatConRef.current?.scrollHeight - chatConRef.current?.clientHeight;
    if (scrollHeightDiff - scrollTopBeforeMsg < 200) {
      // scroll to the bottom then
      chatConRef.current.scrollTop =
        chatConRef.current?.scrollHeight - chatConRef.current?.clientHeight;
      setChatScrolling({
        ...chatScrolling,
        scrollTop:
          chatConRef.current?.scrollHeight - chatConRef.current?.clientHeight,
      });
    }
  };

  const messagesScrollEvent = () => {
    setChatScrolling({
      ...chatScrolling,
      scrollTop: chatConRef.current?.scrollTop,
      firstScroll: true,
    });
  };

  const checkLoginAndJoined = (params) => {
    // eslint-disable-next-line no-underscore-dangle
    if (!rtm._logined) {
      // console.log('Please Login first!');
      return false;
    }

    if (
      !rtm.channels[params.channelName] ||
      (rtm.channels[params.channelName] &&
        !rtm.channels[params.channelName].joined)
    ) {
      // console.log('Please Join first!');
      return false;
    }
    return true;
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    rtm.on('ChannelMessage', async ({ channelName, args }) => {
      const [message, memberId] = args;
      const msgDate = new Date().getTime();
      if (
        message?.messageType === 'IMAGE' &&
        memberId !== rtmChatPeopleDetails.userId
      ) {
        const blob = await rtm.client.downloadMedia(message.mediaId);
        blobToImage(blob, (image) => {
          const scrollTopBeforeMsg = chatConRef.current.scrollTop;
          if (!rtmMsgByDocModRef.current || rtmMsgsArr.length === 1) {
            dispatch(reduxInternal.rtm.actions.setRTMMsgBy(true));
            dispatch(
              reduxInternal.rtm.actions.setRTMMessagesArray({
                name:
                  memberId === rtmChatPeopleDetails?.doctorId
                    ? rtmChatPeopleDetails.doctorName
                    : consultationDetailsData?.doc?.name,
                message: 'image',
                fileName: message.fileName,
                size: message.size,
                image,
                msgBy: 'other',
                date: msgDate,
              })
            );
          } else {
            dispatch(
              reduxInternal.rtm.actions.setRTMMessagesArray({
                name: '',
                message: 'image',
                fileName: message.fileName,
                size: message.size,
                image,
                msgBy: 'other',
                date: msgDate,
              })
            );
          }
          scrollDownOnMessage(scrollTopBeforeMsg);
        });
      }
      if (
        message?.messageType === 'TEXT' &&
        memberId !== rtmChatPeopleDetails.userId
      ) {
        const scrollTopBeforeMsg = chatConRef?.current?.scrollTop;
        console.log('memberId', memberId);
        console.log('rtmChatPeopleDetails', rtmChatPeopleDetails);
        console.log(memberId === rtmChatPeopleDetails?.doctorId);
        console.log(
          'consultationDetailsData?.doc?.name',
          consultationDetailsData?.doc?.name
        );
        if (!rtmMsgByDocModRef.current || rtmMsgsArr.length === 1) {
          dispatch(reduxInternal.rtm.actions.setRTMMsgBy(true));
          console.log('One');
          dispatch(
            reduxInternal.rtm.actions.setRTMMessagesArray({
              name:
                memberId === rtmChatPeopleDetails?.doctorId
                  ? rtmChatPeopleDetails.doctorName
                  : consultationDetailsData?.doc?.name,
              message: message.text,
              msgBy: 'other',
              date: msgDate,
            })
          );
        } else {
          console.log('Two');
          dispatch(
            reduxInternal.rtm.actions.setRTMMessagesArray({
              name: '',
              message: message.text,
              msgBy: 'other',
              date: msgDate,
            })
          );
        }
        scrollDownOnMessage(scrollTopBeforeMsg);
      }
    });

    rtm.on('ConnectionStateChanged', (newState, reason) => {
      if (newState === 'ABORTED') {
        if (reason === 'REMOTE_LOGIN') {
          // console.log('You have already been kicked off!');
        }
      }
    });
  }, [rtm]);

  // eslint-disable-next-line no-unused-vars
  const logoutFromChat = (e) => {
    e.preventDefault();
    // eslint-disable-next-line no-underscore-dangle
    if (!rtm._logined) {
      // console.log('You already logged out');
      return;
    }
    rtm
      .logout()
      .then(() => {
        // eslint-disable-next-line no-underscore-dangle
        rtm._logined = false;
      })
      // eslint-disable-next-line no-unused-vars
      .catch((err) => {});
  };

  const joinChannel = () => {
    // eslint-disable-next-line no-underscore-dangle
    if (!rtm._logined) {
      // console.log('Please Login First');
      // eslint-disable-next-line
      return;
    }

    const params = { ...chatConfig };

    if (
      rtm.channels[params.channelName] ||
      (rtm.channels[params.channelName] &&
        rtm.channels[params.channelName].joined)
    ) {
      // console.log('You already joined');
      // eslint-disable-next-line
      return;
    }

    rtm
      .joinChannel(params.channelName)
      .then(() => {
        rtm.channels[params.channelName].joined = true;
        setTimeout(() => {
          setChatScrolling({
            ...chatScrolling,
            chatScrollHeight: chatConRef.current.scrollHeight,
          });
        }, [50]);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((err) => {
        // console.log(
        //   'Join channel failed, please open console see more details.'
        // );
        // console.log(err);
      });
  };

  const loginToChat = async () => {
    // eslint-disable-next-line no-underscore-dangle
    if (rtm._logined) {
      // console.log('You are already logged in');
      return;
    }
    try {
      rtm.init(chatConfig.appId);
      window.rtm = rtm;
      await rtm
        .login(chatConfig.accountName, chatConfig.token)
        .then(() => {
          // eslint-disable-next-line no-underscore-dangle
          rtm._logined = true;
          joinChannel();
        })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {});
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  // eslint-disable-next-line no-unused-vars
  const leaveChannel = () => {
    // eslint-disable-next-line no-underscore-dangle
    if (!rtm._logined) {
      // console.log('Please Login First!');
      return;
    }

    const params = { ...chatConfig };

    if (
      !rtm.channels[params.channelName] ||
      (rtm.channels[params.channelName] &&
        !rtm.channels[params.channelName].joined)
    ) {
      // show this error in the ui
      // console.log('You have already left!');
    }

    rtm
      .leaveChannel(params.channelName)
      .then(() => {
        if (rtm.channels[params.channelName]) {
          rtm.channels[params.channelName].joined = false;
          rtm.channels[params.channelName] = null;
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((err) => {});
  };

  const resetInputState = () => {
    setMsgInput('');
  };

  const sendChannelMessage = (e) => {
    e.preventDefault();
    const params = { ...chatConfig };

    if (!checkLoginAndJoined(params)) {
      ToastMessages(
        'Could not send your message. Reason: Not logged in to chat.',
        'error'
      );
      return;
    }

    if (e.target[0].value === '') {
      // console.log('Please enter a message before submitting data.');
      return;
    }

    rtm
      .sendChannelMessage(
        { text: e.target[0].value, messageType: 'TEXT' },
        params.channelName
      )
      .then(() => {
        const msgDate = new Date().getTime();
        const scrollTopBeforeMsg = chatConRef.current.scrollTop;
        if (rtmMsgByDoc || rtmMsgsArr.length === 1) {
          dispatch(reduxInternal.rtm.actions.setRTMMsgBy(false));
          dispatch(
            reduxInternal.rtm.actions.setRTMMessagesArray({
              name: profile.name,
              message: e.target[0].value,
              msgBy: 'me',
              date: msgDate,
            })
          );
        } else {
          dispatch(
            reduxInternal.rtm.actions.setRTMMessagesArray({
              name: '',
              message: e.target[0].value,
              msgBy: 'me',
              date: msgDate,
            })
          );
        }
        // now dispatch the data to the backend.
        const chatData = {
          patientId: consultationDetailsData?.userId,
          doctorId: consultationDetailsData?.doctorId,
          description: e.target[0].value,
          file: [],
          type: 'Patient',
          date: msgDate,
        };
        dispatch(
          redux.chat.actions.postChatData({
            data: chatData,
            id: consultationDetailsData?.chatId,
          })
        );
        resetInputState();
        scrollDownOnMessage(scrollTopBeforeMsg);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((err) => {});
  };

  const resetFileInput = () => {
    fileInputRef.current.value = null;
  };

  const uploadFile = (e) => {
    const fileObj = e.target.files && e.target.files[0];
    setFileUploadObj(fileObj);
    // reset the previous file upload response
    dispatch(redux.fileDomain.actions.uploadFilesReset());
    if (!fileObj) {
      return;
    }
    e.preventDefault();
    const params = { ...chatConfig };

    if (!checkLoginAndJoined(params)) {
      ToastMessages(
        'Could not send your message. Reason: Not logged in to chat.',
        'error'
      );
      return;
    }

    if (e.target.files[0].type === 'application/pdf') {
      console.log('Uploading pdf file');
    } else {
      try {
        rtm
          .sendChannelMediaMessage(
            e.target.files[0],
            params.channelName,
            e.target.files[0].name
          )
          .then(() => {
            const msgDate = new Date().getTime();
            const scrollTopBeforeMsg = chatConRef.current.scrollTop;
            // append the image to the view
            if (rtmMsgByDoc || rtmMsgsArr.length === 1) {
              dispatch(reduxInternal.rtm.actions.setRTMMsgBy(false));
              generateImage(e.target.files[0])
                .then((dataUrlFull) => {
                  dispatch(
                    reduxInternal.rtm.actions.setRTMMessagesArray({
                      name: profile.name,
                      message: 'image',
                      fileName: fileObj.name,
                      size: fileObj.size,
                      image: dataUrlFull,
                      msgBy: 'me',
                      date: msgDate,
                    })
                  );
                })
                // eslint-disable-next-line no-unused-vars
                .catch((err) => {});
            } else {
              generateImage(e.target.files[0])
                .then((dataUrlFull) => {
                  dispatch(
                    reduxInternal.rtm.actions.setRTMMessagesArray({
                      name: '',
                      message: 'image',
                      fileName: fileObj.name,
                      size: fileObj.size,
                      image: dataUrlFull,
                      msgBy: 'me',
                      date: msgDate,
                    })
                  );
                })
                // eslint-disable-next-line no-unused-vars
                .catch((err) => {});
            }
            let fileTypeToSend = 'png';
            if (fileObj.type.includes('jpg')) {
              fileTypeToSend = 'jpg';
            }
            if (fileObj.type.includes('jpeg')) {
              fileTypeToSend = 'jpeg';
            }
            const data = {
              filename: fileObj.name,
              fileType: fileTypeToSend,
              userId: profile?.id,
              key: 'chat',
            };
            dispatch(redux.fileDomain.actions.getUploadFilesUrl(data));
            resetFileInput();
            scrollDownOnMessage(scrollTopBeforeMsg);
          })
          // eslint-disable-next-line no-unused-vars
          .catch((err) => {
            resetFileInput();
          });
      } catch (err) {
        resetFileInput();
      }
    }
  };

  useEffect(() => {
    if (getUploadFileUrlError) {
      // set the toast message here.
    }
  }, [getUploadFileUrlError]);

  const openLightBox = async (data) => {
    await setLightBoxImage([data]);
    setIsOpenLightBox(true);
  };

  const closeLightBox = () => {
    setLightBoxImage([]);
    setIsOpenLightBox(false);
  };
  const goToConsultationVideoRoom = () => {
    setShowJoinNotification(false);
    toggleChat();
    joinCallFunc();
    // navigate(
    //   `../../patient/consultation/consultation-room?id=${consultationDetailsData?.id}`,
    //   { replace: true }
    // );
  };

  useEffect(() => {
    loginToChat();
  }, []);

  useEffect(() => {
    if (getUploadFileUrlResponse) {
      dispatch(
        redux.fileDomain.actions.uploadFiles({
          data: fileUploadObj,
          resUrl: getUploadFileUrlResponse?.signUrl,
          dataType: getUploadFileUrlResponse?.fileType,
        })
      );
    }
  }, [getUploadFileUrlResponse]);

  useEffect(() => {
    if (fileUploadResponse) {
      // now dispatch the data to the backend.
      const chatData = {
        patientId: consultationDetailsData?.userId,
        doctorId: consultationDetailsData?.doctorId,
        description: '',
        file: fileUploadResponse?.[0]?.id ? [fileUploadResponse[0].id] : [],
        type: 'Patient',
        date: new Date().getTime(),
      };
      dispatch(
        redux.chat.actions.postChatData({
          data: chatData,
          id: consultationDetailsData?.chatId,
        })
      );
    }
  }, [fileUploadResponse]);

  useEffect(() => {
    // scroll to the bottom initially when there is a change in the chatScrolling object.
    if (
      chatConRef.current?.scrollHeight > chatConRef.current?.clientHeight &&
      chatConRef.current?.scrollTop === 0 &&
      !chatScrolling.firstScroll
    ) {
      chatConRef.current.scrollTop =
        chatConRef.current?.scrollHeight - chatConRef.current.clientHeight;
      setChatScrolling({
        ...chatScrolling,
        scrollTop:
          chatConRef.current?.scrollHeight - chatConRef.current.clientHeight,
      });
    }
  }, [chatScrolling]);

  useEffect(() => {
    if (consultationDetailsData && consultationDetailsData?.agora?.appId) {
      dispatch(
        reduxInternal.rtm.actions.setRTMPeopleDetails({
          doctorId: consultationDetailsData?.doctorId,
          userId: consultationDetailsData?.userId,
          doctorName: consultationDetailsData?.doc?.name,
          userName: consultationDetailsData?.patient?.name,
          clinicName: consultationDetailsData?.doc?.doctor?.clinic || 'N/A',
        })
      );
    }
  }, [consultationDetailsData]);

  return (
    <div
      className={classNames('flex flex-col justify-between chat-container', {
        'show-chat': showChat,
        'hide-chat': !showChat,
      })}
    >
      {isOpenLightBox && (
        <LightBoxComp closeLightBox={closeLightBox} images={lightBoxImage} />
      )}
      <NotificationToastMessages />
      <div className="flex justify-between pl-6 pr-8 py-3 chat-header">
        <h4>Chat</h4>
        <img
          className="cursor-pointer"
          src="/assets/icons/action-icons/close-grey.svg"
          alt="close chat"
          onClick={toggleChat}
        />
      </div>
      <div
        ref={chatConRef}
        onScroll={messagesScrollEvent}
        className="flex flex-col chat-msgs-con"
      >
        <div className="bg-white chat-content-header">
          <div className="pl-6 pr-8 clinic-name">
            <div className="flex justify-center items-center text-base font-semibold px-4 py-2 inner">
              {rtmChatPeopleDetails?.clinicName &&
              rtmChatPeopleDetails?.clinicName !== 'N/A'
                ? rtmChatPeopleDetails?.clinicName
                : rtmChatPeopleDetails?.doctorName}
            </div>
          </div>
        </div>
        <div className="flex flex-col pl-4 pr-6 pt-3 pb-2 chat-msgs">
          {rtmMsgsArr.length > 0 &&
            rtmMsgsArr.map((msg, indexTop) =>
              // eslint-disable-next-line no-nested-ternary
              msg.msgBy === 'other' ? (
                <div key={`doc_${indexTop.toString()}`}>
                  {msg.name && (
                    <div className="text-base mb-1 peer-title">{msg.name}</div>
                  )}
                  {msg.message &&
                  msg.message !== 'image' &&
                  (typeof msg.message === 'string' || msg.message !== '') ? (
                    <div className="flex peer-msg">
                      <div className="px-4 py-2 mb-2 inner">{msg.message}</div>
                    </div>
                  ) : (
                    msg.message &&
                    msg.message !== '' &&
                    msg.message === 'image' && (
                      <div className="flex peer-msg">
                        <div className="px-4 py-2 mb-2 inner">
                          <ChatImageAttachment
                            openLightBox={openLightBox}
                            src={msg?.image?.src ? msg?.image?.src : msg?.image}
                            fileName={msg?.fileName || ''}
                            size={msg?.size || ''}
                            msgBy="Doc"
                          />
                        </div>
                      </div>
                    )
                  )}
                  {msg.date && (
                    <div className="text-sm mb-1 font-normal text-theme-icon-color text-right">
                      {tConvertOne(msg.date)}
                    </div>
                  )}
                </div>
              ) : (
                <div key={`user_${indexTop.toString()}`}>
                  {msg.name && (
                    <div className="text-base mb-1 user-title">{msg.name}</div>
                  )}
                  {msg.message &&
                  msg.message !== 'image' &&
                  (typeof msg.message === 'string' || msg.message !== '') ? (
                    <div className="px-4 py-2 mb-2 user-msg">{msg.message}</div>
                  ) : (
                    msg.message &&
                    msg.message !== '' &&
                    msg.message === 'image' && (
                      <div className="px-4 py-2 mb-2 user-msg">
                        <ChatImageAttachment
                          openLightBox={openLightBox}
                          src={msg?.image?.src ? msg?.image?.src : msg?.image}
                          fileName={msg?.fileName || ''}
                          size={msg?.size || ''}
                          msgBy="Patient"
                        />
                      </div>
                    )
                  )}
                  {msg.date && (
                    <div className="text-sm mb-1 font-normal text-theme-icon-color text-left">
                      {tConvertOne(msg.date)}
                    </div>
                  )}
                </div>
              )
            )}
        </div>
      </div>
      {showJoinNotification && (
        <div className="my-2 mx-3 p-3 rounded-lg text-center join-notification">
          <div className="flex justify-end">
            <img
              onClick={() => setShowJoinNotification(false)}
              className="cursor-pointer"
              src="/assets/icons/action-icons/close-black.svg"
              alt="close notification"
            />
          </div>
          <div className="flex flex-col gap-3">
            <p>Doctor is ready for you.</p>
            <div className="px-8">
              <Button
                label="Join Call"
                height={48}
                type="button"
                primary={true}
                width="100%"
                onClick={goToConsultationVideoRoom}
                centerContent={true}
              />
            </div>
          </div>
        </div>
      )}
      <div className="py-1 px-2 flex items-center type-msg-container">
        <form className="flex" onSubmit={sendChannelMessage}>
          <div className="textarea-con">
            <textarea
              name="user_message"
              value={msgInput}
              placeholder="your message here..."
              onChange={(e) => setMsgInput(e.target.value)}
              maxLength={1500}
            >
              &nbsp;
            </textarea>
          </div>
          <div className="flex items-center chat-form-actions">
            <div className="attach">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="file-upload">
                <img
                  className="cursor-pointer upload-icon"
                  src="/assets/icons/action-icons/attach-files.svg"
                  alt="upload"
                />
              </label>
              <input
                ref={fileInputRef}
                onChange={uploadFile}
                type="file"
                // accept=".jpg,.jpeg,.png,.pdf"
                accept=".jpg,.jpeg,.png"
                name="file-upload"
                id="file-upload"
                className="hidden"
              />
            </div>
            <button type="submit">
              <img
                className="cursor-pointer submit-icon"
                src="/assets/icons/action-icons/msg-submit.svg"
                alt="submit"
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

AgoraChat.propTypes = {
  dispatch: PropTypes.func.isRequired,
  toggleChat: PropTypes.func.isRequired,
  showChat: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  consultationDetailsData: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rtmChatConfigRedux: PropTypes.any.isRequired,
  rtmMsgByDoc: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rtmMsgsArr: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rtmChatPeopleDetails: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fileUploadResponse: PropTypes.object,
  showJoinNotification: PropTypes.bool,
  setShowJoinNotification: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  getUploadFileUrlResponse: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  getUploadFileUrlError: PropTypes.any,
  joinCallFunc: PropTypes.func,
};

AgoraChat.defaultProps = {
  showJoinNotification: false,
  setShowJoinNotification: () => {},
  getUploadFileUrlResponse: null,
  getUploadFileUrlError: null,
  fileUploadResponse: {},
  joinCallFunc: () => {},
};

const mapStateToProps = (state) => ({
  profile: redux.user.selectors.profile()(state),
  consultationDetailsData:
    redux.consultations.selectors.selectPatientConsultationStatusData()(state),
  rtmChatConfigRedux:
    reduxInternal.rtm.selectors.makeSelectRTMChatConfig()(state),
  rtmMsgByDoc: reduxInternal.rtm.selectors.makeSelectRTMMessageBy()(state),
  rtmMsgsArr: reduxInternal.rtm.selectors.makeSelectRTMMsgsArray()(state),
  rtmChatPeopleDetails:
    reduxInternal.rtm.selectors.makeSelectRTMChatPeopleDetails()(state),
  fileUploadResponse: redux.fileDomain.selectors.uploadFiles()(state),
  getUploadFileUrlResponse:
    redux.fileDomain.selectors.getUploadFilesUrl()(state),
  getUploadFileUrlError:
    redux.fileDomain.selectors.getUploadFilesUrlError()(state),
});

export default connect(mapStateToProps)(AgoraChat);
