/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import './style.css';
import VideoActionsOption from '../../components/VideoActionsOption';
import JoinCallCard from '../../features/JoinCallCard';

const VideoPreCallTest = ({
  handleJoinCall,
  localVideoRef,
  localVideoTrack,
  localAudioTrack,
  isLocalVideoError,
  isLocalAudioError,
  toggleCam,
  toggleMic,
}) => {
  const navigate = useNavigate();
  return (
    <div className="relative h-full w-full bg-gray-500">
      <ReactTooltip />
      {!localVideoTrack && (
        <div className="absolute w-full h-full pre-call-test-video-background" />
      )}
      <div ref={localVideoRef} className="absolute w-full h-full z-0" />
      <div className="absolute w-full h-full flex flex-col justify-between items-center z-10">
        <div className="flex w-full justify-between items-center px-3 md:px-8 font-semibold pre-call-test-header-container">
          <div />
          <div className="flex gap-6">
            <img
              src="/assets/icons/video-icons/need-help-white.svg"
              alt="need help icon"
            />
            {window.innerWidth > 768 ? (
              <p>
                <a
                  href="https://help.doctoroncall.com.my/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Need Help?
                </a>
              </p>
            ) : null}
          </div>
        </div>
        <JoinCallCard
          handleJoinCall={handleJoinCall}
          isLocalAudioError={isLocalAudioError}
          isLocalVideoError={isLocalVideoError}
        />
        <div className="flex flex-col gap-4 justify-center items-center w-full pre-call-test-options-container">
          {/* <div className="md:hidden">
            <AudioTestBars frequency={localAudioTrack ? audioFreqLevel : 0} />
          </div> */}
          <div className="flex gap-3 md:gap-6">
            <VideoActionsOption
              img={`/assets/icons/video-icons/camera${
                !localVideoTrack && !isLocalVideoError ? '-red' : ''
              }.svg`}
              title="Cam"
              onClickFunc={toggleCam}
              isError={isLocalVideoError}
            />
            <div
              data-tip={`${
                isLocalAudioError
                  ? 'There seems to be an issue with your microphone and camera, please check your settings.'
                  : 'Your microphone is working fine'
              }`}
            >
              <VideoActionsOption
                img={`/assets/icons/video-icons/microphone${
                  !localAudioTrack && !isLocalAudioError ? '-red' : ''
                }.svg`}
                title="Mic"
                onClickFunc={toggleMic}
                isError={isLocalAudioError}
              />
            </div>
            {/* <div className="hidden md:flex">
              <AudioTestBars frequency={localAudioTrack ? audioFreqLevel : 0} />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

VideoPreCallTest.propTypes = {
  handleJoinCall: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  localVideoRef: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  localVideoTrack: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  localAudioTrack: PropTypes.any.isRequired,
  isLocalVideoError: PropTypes.bool,
  isLocalAudioError: PropTypes.bool,
  toggleCam: PropTypes.func.isRequired,
  toggleMic: PropTypes.func.isRequired,
};

VideoPreCallTest.defaultProps = {
  isLocalVideoError: false,
  isLocalAudioError: false,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(VideoPreCallTest);
