import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const VideoInfoContainer = ({ avatar, name, message, local }) => (
  <div
    className={`flex flex-col justify-center ${
      local ? 'gap-2' : 'gap-10'
    } items-center info-container`}
  >
    {avatar.length > 0 ? (
      <img src={avatar} alt="profile icon" className="rounded-full w-24 h-24" />
    ) : (
      <div className="name-con">{name ? name[0] : ''}</div>
    )}
    <div className="flex flex-col gap-4 px-10">
      <h4 className="font-semibold title">{name || ''}</h4>
      <p className="text-base font-normal info">{message || ''}</p>
    </div>
  </div>
);

VideoInfoContainer.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  local: PropTypes.bool.isRequired,
};

VideoInfoContainer.defaultProps = {
  avatar: '',
};

export default VideoInfoContainer;
