import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './style.css';

const LightBoxComp = ({ images, closeLightBox }) => (
  <Lightbox mainSrc={images[0]} onCloseRequest={closeLightBox} />
);

LightBoxComp.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  images: PropTypes.object.isRequired,
  closeLightBox: PropTypes.func.isRequired,
};

LightBoxComp.defaultProps = {};

export default LightBoxComp;
