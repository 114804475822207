import React from 'react';
import PropTypes from 'prop-types';
import { Oval } from 'react-loader-spinner';

function CallStarting({ text }) {
  return (
    <div className="flex flex-col justify-center items-center gap-9 px-3 w-full h-screen bg-theme-dark-charcoal absolute top-0 z-50">
      <div className="w-20 h-20 sm:w-24 sm:h-24">
        <Oval
          ariaLabel="loading-indicator"
          height="100%"
          width="100%"
          strokeWidth={5}
          color="#ffffff"
          secondaryColor="#999999"
        />
      </div>
      <div className="text-white text-base font-normal text-center">{text}</div>
    </div>
  );
}

CallStarting.propTypes = {
  text: PropTypes.string,
};

CallStarting.defaultProps = {
  text: 'Your audio call will start in a second...',
};

export default CallStarting;
